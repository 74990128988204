import { css } from "styled-components"

export default css`
  body {
    font-family: "Merriweather", serif;
    background-color: black;
    color: white;
  }
  h1,
  h3 {
    font-family: "Raleway", sans-serif;
  }
  h3,
  h4 {
    font-weight: normal;
  }
  button {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    outline: none;
  }
  a {
    color: inherit;
  }
  @media (max-width: 500px) {
    body {
      font-size: 12px;
    }
  }
`
